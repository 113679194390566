import '../App.css';
import { Card, CardContent, Container, Paper, Step, StepLabel, Toolbar, Typography, Grid, TextField, Button, TableContainer, TableBody, TableRow, Table, TableHead, TableCell, CardMedia, CardActions, Select, MenuItem, Alert, IconButton, Box } from '@mui/material';
import React from 'react';
import EditIcon from '@mui/icons-material/Edit'
import { Link, useNavigate, useParams } from 'react-router-dom';

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { finances, getNetAnnualIncome, getLoanAnnualTotal, getNetExpenseAnnualTotal, getCreditAnnualTotal, getLoanMonthlyPayment, getSavingsAnnualTotal, calculateSavings, calculateRepayments, getIncomeAfterExpenses, getNetIncome, getIncome, getAnnualIncome, deleteSavings, deleteExpense, deleteLoan, deleteCredit, deleteIncome, getExpenseAnnualTotal, settings, getSavingsIncludingPension } from '../store/store';

import DeleteIcon from '@mui/icons-material/Delete'
import { NumericFormat } from 'react-number-format';
import { MoneyTextFormat, PercentTextFormat } from '../money';
import { ExpenseMap } from '../expenses/Expense';

import Carousel from 'react-material-ui-carousel'
import DeleteDialog from '../deletedialog';

import { DataGrid } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import { useFinances } from '../store/FinancesContext';
import AdviceCards from '../advice-cards/AdviceCards';

import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

ChartJS.register(ArcElement, Tooltip, Legend);

function Annual() {

    var finances = useFinances()

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));

    var { year } = useParams()
    if (!parseInt(year)) {
        year = new Date().getFullYear()
    }

    const income = finances.annualIncome[parseInt(year)]

    const remaining = getIncomeAfterExpenses(finances, parseInt(year))

    const data = {
        labels: ['Credit', 'Loans', 'Expenses', 'Savings', 'Disposable'],
        datasets: [
            {
                label: '£',
                data: [getCreditAnnualTotal(finances, parseInt(year)), getLoanAnnualTotal(finances, parseInt(year)), getNetExpenseAnnualTotal(finances, parseInt(year)), getSavingsAnnualTotal(finances, parseInt(year)), remaining],
                backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                    'rgba(75, 192, 192, 0.2)',
                    'rgba(255, 159, 64, 0.2)',
                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(255, 159, 64, 1)',
                ],
                borderWidth: 1,
            },
        ],

    };

    const loans = finances.loans

    const credit = finances.credit
    // TODO filter credit to see if it will have been paid off or be valid in that year

    const [showDelete, setShowDelete] = React.useState()

    const navigate = useNavigate()

    return (
        <>

            {showDelete &&
                <DeleteDialog delete={showDelete.delete} name={showDelete.name} handleClose={() => setShowDelete(null)} />
            }
            <Grid container item spacing={2} xs={12}>
                <Grid container item xs={12}>
                    <Grid item xs={12}>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={year}
                            onChange={(e) => {
                                navigate(`/annual/${e.target.value}`)
                            }}
                            label="Age"
                            fullWidth
                        >
                            {
                                (() => {
                                    var startYear = finances.income.reduce((prev, cur) => {
                                        var per = dayjs(cur.periods.reduce((pPrev, pCur) => {
                                            return pCur.start ? dayjs(pCur.start, 'YYYY-MM-DD') < dayjs(pPrev) ? pCur.start : pPrev : pPrev
                                        }, dayjs()), 'YYYY-MM-DD')

                                        return dayjs(per, 'YYYYY-MM-DD') < prev ? per : prev
                                    }, dayjs()).year()

                                    var items = []
                                    for (var i = startYear; i < 2090; i++) {
                                        items.push(<MenuItem value={parseInt(i)}>{parseInt(i)}</MenuItem>)
                                    }

                                    return items
                                })()
                            }
                        </Select>
                    </Grid>
                </Grid>
                <Grid container xs={12} item spacing={2}>
                    <Grid container item xs={12} md={3}>
                        <Paper style={{ width: '100%', padding: '20px' }}>
                            <Grid container spacing={2} item xs={12}>
                                <Grid container item xs={6} md={12}>
                                    <Grid item xs={12}>
                                        <Typography variant='h6'>Net Income</Typography>
                                    </Grid>
                                    <Grid container item xs={12}>
                                        <Typography variant='h4'><MoneyTextFormat value={parseInt(income.net / 1000)} />K</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography style={{ color: 'grey' }}><MoneyTextFormat value={Math.round(income.gross - income.net)} /> deductions</Typography>
                                    </Grid>
                                </Grid>
                                <Grid container item xs={6} md={12}>
                                    <Grid item xs={12}>
                                        <Typography variant='h6' sx={{ textAlign: { xs: 'right', md: 'left' } }} >After Outgoings</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography sx={{ textAlign: { xs: 'right', md: 'left' } }} variant='h4' style={{ color: (remaining / income.net) < 0 ? 'red' : (remaining / income.net) > 0.15 ? 'green' : 'orange' }}><MoneyTextFormat value={parseInt(remaining / 1000)} />K</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography sx={{ textAlign: { xs: 'right', md: 'left' } }} style={{ color: 'grey' }}><MoneyTextFormat value={Math.round(remaining / 12)} /> per month</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={4} alignItems={'center'}>
                        <Paper>
                            <Doughnut style={{ margin: '0 auto', display: 'block' }} data={data} options={{ plugins: { legend: { position: 'right' } } }} />
                        </Paper>
                    </Grid>
                    {matches && <Grid container item md={5}>
                        <Card>
                            <CardMedia
                                component="img"
                                alt="green iguana"
                                height="140"
                                image="https://www.moneyhelper.org.uk/content/dam/maps/en/l2-images/man-lying-on-bed-using-laptop-while-young-boy-plays-on-his-back.jpg.pic.624.312.low.jpg"
                            />
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="div">
                                    Family Finances
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    If you work 37.5 hours a week, should you be able to enjoy a good quality of life? What does that mean for someone living in the UK?
                                </Typography>
                            </CardContent>
                            <CardActions style={{ mt: "auto", justifyContent: 'end' }}>
                                <Button target='_blank' href="https://simonodonoghue.blog/2024/10/21/family-finances/" size="small">Read More</Button>
                            </CardActions>
                        </Card>

                    </Grid>}
                    {
                        getIncomeAfterExpenses(finances, year) > finances.annualIncome[year].net * 0.12 &&
                        <Grid item xs={12}>
                            <Alert severity='info'>It looks like you have some disposable income. Have you added all of your expenses, loans and credit? If you have, have you thought about saving for your short and long term goals?</Alert>
                        </Grid>
                    }
                    <Grid container spacing={2} item xs={12}>
                        <Grid item xs={12}>
                            <Typography variant='h5'>Income</Typography>
                        </Grid>
                        <Grid container item xs={12} spacing={2}>
                            <Grid container item xs={12} spacing={2} md={6}>
                                <Grid alignContent={'flex-start'} container item spacing={2} xs={12}>
                                    <Grid item xs={12}>
                                        <Paper>
                                            <DataGrid disableColumnResize autoHeight disableColumnMenu disableRowSelectionOnClick columns={
                                                [
                                                    {
                                                        field: 'id'
                                                    },
                                                    {
                                                        field: 'income',
                                                        flex: 0.5,
                                                        headerName: 'Income'
                                                    },
                                                    {
                                                        field: 'gross',
                                                        flex: 0.3,
                                                        headerName: 'Gross',
                                                        renderCell: (a) => {
                                                            return <MoneyTextFormat value={a.row.gross} />
                                                        }
                                                    },
                                                    {
                                                        field: 'edit',
                                                        flex: 0.2,
                                                        align: 'right',
                                                        renderCell: (a) => {
                                                            return <IconButton component={Link} to={a.row.type == 'pension' ? `/pension` : `/income/${a.id}`}><EditIcon /></IconButton>
                                                        },
                                                        headerName: ''
                                                    }
                                                ]
                                            } initialState={{
                                                columns: {
                                                    columnVisibilityModel: {
                                                        id: false
                                                    }
                                                },
                                                pagination: {
                                                    paginationModel: {
                                                        pageSize: 5
                                                    }
                                                }
                                            }} rows={income.incomes.flatMap((inc) => {
                                                return inc.incomes.map((m) => {
                                                    return {
                                                        id: m.id,
                                                        type: m.type,
                                                        income: m.name,
                                                        gross: m.gross
                                                    }

                                                })
                                            })
                                            } />
                                        </Paper>
                                    </Grid>
                                    <Grid container item spacing={2} justifyContent='flex-end'>
                                        <Grid item>
                                            <Button component={Link} to={`/tax/${year}`} variant='outlined'>View Tax</Button>
                                        </Grid>
                                        <Grid item>
                                            <Button component={Link} to={`/income`} variant='contained'>Add Income</Button>
                                        </Grid>
                                    </Grid>
                                </Grid>


                            </Grid>
                            <Grid container item xs={12} md={6} >
                                <AdviceCards type='income' year={year} />
                            </Grid>
                        </Grid>

                    </Grid>
                    <Grid container spacing={2} item xs={12}>
                        <Grid item xs={12}>
                            <Typography variant='h5'>Credit</Typography>
                        </Grid>
                        <Grid container item xs={12} spacing={2}>
                            <Grid order={{ xs: 1, md: 0 }} container item xs={12} md={6} >
                                <AdviceCards type='credit' year={year} />
                            </Grid>
                            <Grid order={{ xs: 0, md: 1 }} container item xs={12} spacing={2} md={6}>
                                <Grid alignContent={'flex-start'} spacing={2} container item xs={12}>
                                    <Grid item xs={12}>
                                        <Paper>
                                            <DataGrid disableColumnResize autoHeight disableColumnMenu disableRowSelectionOnClick columns={
                                                [
                                                    {
                                                        field: 'id'
                                                    },
                                                    {
                                                        field: 'credit',
                                                        flex: 0.4,
                                                        headerName: 'Credit'
                                                    },
                                                    {
                                                        field: 'annual',
                                                        flex: 0.25,
                                                        headerName: 'Yearly',
                                                        renderCell: (a) => {
                                                            return <MoneyTextFormat value={a.row.annual ? a.row.annual : 0} />
                                                        }
                                                    },
                                                    {
                                                        field: 'edit',
                                                        align: 'right',
                                                        flex: 0.175,
                                                        renderCell: (a) => {
                                                            return <IconButton component={Link} to={`/credit/${a.id}`}><EditIcon /></IconButton>
                                                        },
                                                        headerName: ''
                                                    }
                                                ]
                                            } initialState={{
                                                columns: {
                                                    columnVisibilityModel: {
                                                        id: false
                                                    }
                                                },
                                                pagination: {
                                                    paginationModel: {
                                                        pageSize: 5
                                                    }
                                                }
                                            }} rows={finances.credit.filter((f) => f.periods.find((c) => parseInt(year) >= dayjs(c.start).year() && (c.end == null ? true : parseInt(year) <= dayjs(c.end).year()))).map((c) => {
                                                var repayments = calculateRepayments(finances, c)
                                                var scope = c.periods.filter((c) => {
                                                    return parseInt(year) >= dayjs(c.start).year() && (c.end == null ? true : parseInt(year) <= dayjs(c.end).year())
                                                })[0]

                                                return {
                                                    id: c.id,
                                                    credit: c.name,
                                                    apr: scope.apr * 100,
                                                    annual: repayments.find((f) => f.year == year)?.payments,
                                                    monthly: repayments.find((f) => f.year == parseInt(year)) ? scope.monthly : 0
                                                }


                                            })
                                            } />
                                        </Paper>
                                    </Grid>
                                    <Grid container xs={12} item spacing={2} justifyContent='flex-end'>
                                        <Grid item>
                                            <Button component={Link} to={`/credit`} variant='contained'>Add Credit</Button>

                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container item spacing={2} xs={12}>
                        <Grid item>
                            <Typography variant='h5'>Loans</Typography>
                        </Grid>
                        <Grid container item xs={12} spacing={2}>
                            <Grid container item xs={12} spacing={2} md={6}>
                                <Grid alignContent={'flex-start'} spacing={2} container item xs={12}>
                                    <Grid item xs={12}>
                                        <Paper>
                                            <DataGrid disableColumnResize autoHeight disableColumnMenu disableRowSelectionOnClick columns={
                                                [
                                                    {
                                                        field: 'id'
                                                    },
                                                    {
                                                        field: 'loan',
                                                        flex: 0.4,
                                                        headerName: 'Loan'
                                                    },
                                                    {
                                                        field: 'monthly',
                                                        flex: 0.25,
                                                        headerName: 'Yearly',
                                                        renderCell: (a) => {
                                                            return <MoneyTextFormat value={a.row.annual} />
                                                        }
                                                    },
                                                    {
                                                        field: 'edit',
                                                        align: 'right',
                                                        flex: 0.175,
                                                        renderCell: (a) => {
                                                            return <IconButton component={Link} to={`/loan/${a.id}`}><EditIcon /></IconButton>
                                                        },
                                                        headerName: ''
                                                    }
                                                ]
                                            } initialState={{
                                                columns: {
                                                    columnVisibilityModel: {
                                                        id: false
                                                    }
                                                },
                                                pagination: {
                                                    paginationModel: {
                                                        pageSize: 5
                                                    }
                                                },
                                                sorting: {
                                                    sortModel: [{ field: 'monthly', sort: 'desc' }]
                                                }
                                            }} rows={finances.loans.flatMap((c) => {
                                                var repaymemts = calculateRepayments(finances, c)
                                                if (repaymemts.find((f) => f.year == year)) {
                                                    var per = c.periods.filter((c) => {
                                                        return parseInt(year) >= dayjs(c.start, 'YYYY-MM-DD').year() && (c.end == null ? true : parseInt(year) <= dayjs(c.end, 'YYYY-MM-DD').year())
                                                    })[0]

                                                    var monthly = null

                                                    if (c.type == 'student') {
                                                        var ann = income.incomes.find((f) => f.who == c.who)?.taxableIncome

                                                        var refData = settings.studentloan.find((f) => {
                                                            if (f.type == c.subtype) {
                                                                var s = dayjs(`${f.start}`, 'YYYY')
                                                                var e = f.end ? dayjs(`${f.end}`, 'YYYY') : null
                                                                var y = dayjs(`${year}`, 'YYYY')

                                                                return y >= s && (e == null || y <= e)
                                                            }
                                                            return false
                                                        })

                                                        monthly = ((ann - refData.threshold) * refData.percent) / 12


                                                    } else if (per.monthly) {
                                                        monthly = per.monthly
                                                    } else {
                                                        monthly = Math.round(getLoanMonthlyPayment(finances, c, repaymemts)[per.start])
                                                    }

                                                    return {
                                                        id: c.id,
                                                        loan: c.name ? c.name : 'Student Loan',
                                                        end: repaymemts[repaymemts.length - 1].year,
                                                        monthly: monthly,
                                                        annual: repaymemts.find((f) => f.year == year).payments
                                                    }

                                                }

                                                return []

                                            })} />
                                        </Paper>
                                    </Grid>
                                    <Grid container item justifyContent='flex-end' xs={12}>
                                        <Button variant='contained' component={Link} to={`/loan`}>Add Loan</Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} md={6} >
                                <AdviceCards type='loans' year={year} />
                            </Grid>
                        </Grid>

                    </Grid>
                </Grid>
                <Grid container spacing={2} item xs={12}>
                    <Grid item>
                        <Typography variant='h5'>Expenses</Typography>
                    </Grid>
                    <Grid container item xs={12} spacing={2}>
                        <Grid order={{ xs: 1, md: 0 }} container item xs={12} md={6} >
                            <AdviceCards type='expenses' year={year} />
                        </Grid>
                        <Grid order={{ xs: 0, md: 1 }} container item xs={12} spacing={2} md={6}>
                            <Grid alignContent={'flex-start'} spacing={2} container item xs={12}>
                                <Grid item xs={12}>
                                    <Paper>
                                        <DataGrid disableColumnResize autoHeight disableColumnMenu disableRowSelectionOnClick columns={
                                            [
                                                {
                                                    field: 'id'
                                                },
                                                {
                                                    field: 'expense',
                                                    flex: 0.4,
                                                    headerName: 'Expense',
                                                    renderCell: (a) => {
                                                        return <div style={{ display: 'flex' }}>
                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                {a.row.increase && <ArrowUpwardIcon fontSize='small' htmlColor='orange' />}
                                                                {a.row.decrease && <ArrowDownwardIcon fontSize='small' htmlColor='green' />}
                                                            </div>
                                                            <div>{a.row.expense}</div>
                                                        </div>

                                                    }
                                                },
                                                {
                                                    field: 'amount',
                                                    flex: 0.25,
                                                    headerName: 'Yearly',
                                                    renderCell: (a) => {
                                                        return <MoneyTextFormat value={a.row.amount} />
                                                    }
                                                },
                                                {
                                                    field: 'edit',
                                                    align: 'right',
                                                    flex: 0.175,
                                                    renderCell: (a) => {
                                                        return <IconButton component={Link} to={`/expense/${a.id}`}><EditIcon /></IconButton>
                                                    },
                                                    headerName: ''
                                                }
                                            ]
                                        } initialState={{
                                            columns: {
                                                columnVisibilityModel: {
                                                    id: false
                                                }
                                            },
                                            pagination: {
                                                paginationModel: {
                                                    pageSize: 5
                                                }
                                            },
                                            sorting: {
                                                sortModel: [{ field: 'amount', sort: 'desc' }]
                                            }
                                        }} rows={finances.expenses.flatMap((expense) => {
                                            var ind = expense.periods.findIndex((f) => dayjs(f.start, 'YYYY-MM-DD').year() == year)

                                            var expenseAnnualTotal = getExpenseAnnualTotal(finances, expense, year)

                                            if (expenseAnnualTotal > -1) {
                                                return {
                                                    id: expense.id,
                                                    expense: expense.type == 'other' ? expense.customname : ExpenseMap[expense.type],
                                                    amount: expenseAnnualTotal,
                                                    increase: ind > 0 ? expense.periods[ind - 1].amount < expense.periods[ind].amount : ind == 0,
                                                    decrease: ind > 0 && expense.periods[ind].amount < expense.periods[ind - 1].amount
                                                }
                                            }

                                            return []
                                        })} />
                                    </Paper>
                                </Grid>
                                <Grid container spacing={2} item justifyContent='flex-end' xs={12}>
                                    <Grid item>
                                        <Button component={Link} to={`/expense`} variant='contained'>Add Expense</Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container item spacing={2} xs={12}>

                    <Grid item>
                        <Typography variant='h5'>Savings & Investments</Typography>
                    </Grid>
                    <Grid container item xs={12} spacing={2}>
                        <Grid container item xs={12} spacing={2} md={6}>
                            <Grid alignContent={'flex-start'} spacing={2} container item xs={12}>
                                <Grid item xs={12}>
                                    <Paper>
                                        <DataGrid disableColumnResize autoHeight disableColumnMenu disableRowSelectionOnClick columns={
                                            [
                                                {
                                                    field: 'id'
                                                },
                                                {
                                                    field: 'savings',
                                                    flex: 0.4,
                                                    headerName: 'Savings',
                                                    renderCell: (a) => {
                                                        return <div style={{ display: 'flex' }}>
                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                {a.row.increase && <ArrowUpwardIcon fontSize='small' htmlColor='green' />}
                                                                {a.row.decrease && <ArrowDownwardIcon fontSize='small' htmlColor='orange' />}

                                                            </div>
                                                            <div>
                                                                {a.row.savings}
                                                            </div>
                                                        </div>
                                                    }


                                                },
                                                {
                                                    field: 'saved',
                                                    flex: 0.25,
                                                    headerName: 'Saved',
                                                    renderCell: (a) => {
                                                        return <MoneyTextFormat value={a.row.saved} />
                                                    }
                                                },
                                                {
                                                    field: 'edit',
                                                    align: 'right',
                                                    flex: 0.175,
                                                    renderCell: (a) => {
                                                        return <IconButton component={Link} to={a.row.type != 'pension' ? `/savings/${a.id}` : `/pension`}><EditIcon /></IconButton>
                                                    },
                                                    headerName: ''
                                                }
                                            ]
                                        } initialState={{
                                            columns: {
                                                columnVisibilityModel: {
                                                    id: false
                                                }
                                            },
                                            pagination: {
                                                paginationModel: {
                                                    pageSize: 5
                                                }
                                            },
                                            sorting: {
                                                sortModel: [{ field: 'saved', sort: 'desc' }]
                                            }
                                        }} rows={getSavingsIncludingPension(finances).flatMap((savings) => {
                                            var lSavings = savings.periods.find((f) => dayjs(f.start, 'YYYY-MM-DD').year() <= year && (f.end == null || dayjs(f.end, 'YYYY-MM-DD').year() >= year))
                                            var depositAmount = savings.deposits?.filter((f) => year >= f.start && (f.end == null || year <= f.end)).reduce((prev, cur) => prev + cur.amount, 0)

                                            var calculateContribution = (period) => {
                                                return period.monthly != null ? period.monthly * 12 : income.net * period.percent
                                            }

                                            if (lSavings) {
                                                var contrib = calculateContribution(lSavings)
                                                var ind = savings.periods.findIndex((f) => dayjs(f.start, 'YYYY-MM-DD').year() == year)

                                                return {
                                                    id: savings.id,
                                                    type: savings.type,
                                                    savings: savings.name,
                                                    saved: contrib + (depositAmount ? depositAmount : 0),
                                                    increase: savings.type != 'pension' && ind > 0 ? calculateContribution(savings.periods[ind - 1]) < calculateContribution(savings.periods[ind]) : ind == 0,
                                                    decrease: savings.type != 'pension' && ind > 0 && calculateContribution(savings.periods[ind]) < calculateContribution(savings.periods[ind - 1])
                                                }
                                            }
                                            return []
                                        })} />
                                    </Paper>
                                </Grid>
                                <Grid container item justifyContent='flex-end' xs={12}>
                                    <Button component={Link} to={`/savings`} variant='contained'>Add Savings</Button>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid container item xs={12} md={6} >
                            <AdviceCards type='savings' year={year} />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>

    );
}

export default Annual;
