import '../App.css';
import { Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText, Paper, Step, StepLabel, Toolbar, Typography, Grid, TextField, Button, InputLabel, Select, MenuItem, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Alert, Tab, CardMedia, CardActions, IconButton } from '@mui/material';
import React from 'react';
import { Chart, Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';

import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'



import { calculateRepayments, calculateSavings, finances, getAnnualIncome, getCreditAnnualTotal, getNetExpenseAnnualTotal, getLoanAnnualTotal, getNetAnnualIncome, getSavingsAnnualTotal, deleteExpense, deleteLoan, deleteCredit, deleteSavings, getLoan, getSavingsIncludingPension } from '../store/store';
import { Link, useNavigate } from 'react-router-dom';
import { MoneyTextFormat, PercentTextFormat } from '../money';
import Carousel from 'react-material-ui-carousel';
import DeleteDialog from '../deletedialog';
import { useFinances, useFinancesDispatch } from '../store/FinancesContext';
import AdviceCards from '../advice-cards/AdviceCards';
import { DataGrid } from '@mui/x-data-grid';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import dayjs from 'dayjs';


ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);


function Outlook() {

    var navigate = useNavigate()

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));

    var finances = useFinances()
    var financesDispatch = useFinancesDispatch()

    var [endDate, setEndDate] = React.useState(2075)

    var minYear = finances.income.reduce((prev, cur) => {
        var per = dayjs(cur.periods.reduce((pPrev, pCur) => {
            return pCur.start ? dayjs(pCur.start, 'YYYY-MM-DD') < dayjs(pPrev) ? pCur.start : pPrev : pPrev
        }, dayjs()), 'YYYY-MM-DD')

        return dayjs(per, 'YYYYY-MM-DD') < prev ? per : prev
    }, dayjs()).year()

    var maxYear = 2090

    var [startDate, setStartDate] = React.useState(minYear)

    const options = {
        elements: {
            point: {
                backgroundColor: (p) => {
                    return p.raw?.y < 0 ? '#FF0000' : ChartJS.defaults.backgroundColor
                }
            }
        },
        responsive: true,
        plugins: {
            legend: {
                position: 'bottom',
            }
        },
        scales: {
            x: {
                type: 'linear',
                min: startDate,
                max: endDate,
                ticks: {
                    
                    callback: function (value) {
                        return parseInt(value)
                    }
                }
            }
        }
    };


    const labels = [];
    for (var i = startDate; i <= endDate; i++) {
        labels.push(i)
    }

    var [negativeDisposable, setNegativeDisposable] = React.useState(false)

    var [helpText, setHelpText] = React.useState(false)


    const [showDelete, setShowDelete] = React.useState()

    return (
        <>
            {showDelete &&
                <DeleteDialog delete={showDelete.delete} name={showDelete.name} handleClose={() => setShowDelete(null)} />
            }
            {helpText &&
                <Dialog
                    open={true}
                    onClose={() => {
                        setHelpText(null)
                    }}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        Help
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {helpText}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button color='secondary' onClick={() => setHelpText(null)}>Ok</Button>
                    </DialogActions>
                </Dialog>
            }
            <Grid container item spacing={2}>
                <Grid container spacing={2} item>
                    <Grid item xs={6}>
                        <InputLabel htmlFor="standard-adornment-amount">Start</InputLabel>
                        <Select
                            key={`start-filter`}
                            value={startDate}
                            onChange={(e) => {
                                setStartDate(parseInt(e.target.value))
                            }}
                            fullWidth
                        >
                            {
                                (() => {
                                    var items = []
                                    for (var i = minYear; i < 2100; i++) {
                                        items.push(<MenuItem value={i}>{i}</MenuItem>)
                                    }

                                    return items
                                })()
                            }
                        </Select>
                    </Grid>
                    <Grid item xs={6}>
                        <InputLabel htmlFor="standard-adornment-amount">End</InputLabel>
                        <Select
                            key={`end-filter`}
                            value={endDate}
                            onChange={(e) => {
                                setEndDate(parseInt(e.target.value))
                            }}
                            fullWidth
                        >
                            {
                                (() => {
                                    var items = []
                                    for (var i = minYear; i < 2100; i++) {
                                        items.push(<MenuItem value={i}>{i}</MenuItem>)
                                    }

                                    return items
                                })()
                            }
                        </Select>
                    </Grid>
                </Grid>
                <Grid container item xs={12}>
                    <Grid item xs={6}>
                        <Typography variant='h6' style={{ flexGrow: 1 }}>Money Allocation</Typography>
                    </Grid>
                    <Grid container item xs={6} justifyContent='flex-end'>
                        <Button onClick={() => setHelpText("Not every expense can be planned, and your disposable income covers thoes expenses. It's the coffee and breakfast you enjoy on a Satuday, the video game you're been waiting 6 months for and the Friday night drinks after work when the suns out. Once you've paid off high-interest debt and are investing in your future, what's left is for you to enjoy your life in the here and now. Typically, 10% of your income after tax will suffice.")}>LEARN MORE</Button>
                    </Grid>
                </Grid>
                {negativeDisposable &&
                    <Grid item xs={12}>
                        <Alert severity='warning'>You're not bringing in enough money to cover your outgoings. See the red dots in chart below.</Alert>
                    </Grid>
                }
                <Grid container spacing={2} item xs={12}>
                    <Grid xs={12} item>
                        <Paper style={{ padding: '10px' }}>
                            <Line key={`${startDate}+${endDate}`} data={{
                                datasets: (() => {
                                    var disposableColor = `#${Math.floor(Math.random() * 16777215).toString(16)}`
                                    return [
                                        {
                                            label: 'Disposable Income (Actual)',
                                            data: (() => {
                                                var credit = finances.credit.map((m) => calculateRepayments(finances, m))
                                                var loans = finances.loans.map((m) => calculateRepayments(finances, m))



                                                return labels.map((m) => {
                                                    var calc = finances.annualIncome[m].net - (credit.reduce((pre, cur) => pre + (cur.find((f) => f.year == m) ? cur.find((f) => f.year == m).payments : 0), 0) + loans.reduce((pre, cur) => pre + (cur.find((f) => f.year == m) ? cur.find((f) => f.year == m).payments : 0), 0) + getNetExpenseAnnualTotal(finances, m) + getSavingsAnnualTotal(finances, m))
                                                    return {
                                                        x: m,
                                                        y: calc,
                                                    }
                                                })
                                            })(),
                                            borderColor: disposableColor,
                                            pointStyle: false
                                        },
                                        {
                                            label: 'Disposable Income (Average)',
                                            data: (() => {
                                                return labels.map((m) => {
                                                    return {
                                                        x: m,
                                                        y: finances.annualIncome[m].net * 0.06
                                                    }
                                                })
                                            })(),
                                            borderColor: `${disposableColor}40`,
                                            pointStyle: false
                                        },
                                        {
                                            label: 'Savings',
                                            data: labels.map((m) => {
                                                return {
                                                    x: m,
                                                    y: getSavingsAnnualTotal(finances, m)
                                                }
                                            }),
                                            borderColor: `#${Math.floor(Math.random() * 16777215).toString(16)}`,
                                            pointStyle: false,
                                            hidden: true
                                        },
                                        {
                                            label: 'Expenses',
                                            data: labels.map((m) => {
                                                return {
                                                    x: m,
                                                    y: getNetExpenseAnnualTotal(finances, m)
                                                }
                                            }),
                                            borderColor: `#${Math.floor(Math.random() * 16777215).toString(16)}`,
                                            pointStyle: false,
                                            hidden: true
                                        },
                                        {
                                            label: 'Credit',
                                            data: (() => {
                                                var credit = finances.credit.map((m) => calculateRepayments(finances, m))

                                                return labels.map((m) => {
                                                    return {
                                                        x: m,
                                                        y: credit.reduce((pre, cur) => pre + (cur.find((f) => f.year == m) ? cur.find((f) => f.year == m).payments : 0), 0)
                                                    }
                                                })
                                            })(),
                                            borderColor: `#${Math.floor(Math.random() * 16777215).toString(16)}`,
                                            pointStyle: false,
                                            hidden: true
                                        },
                                        {
                                            label: 'Loans',
                                            data: (() => {
                                                var loans = finances.loans.map((m) => calculateRepayments(finances, m))

                                                return labels.map((m) => {
                                                    return {
                                                        x: m,
                                                        y: loans.reduce((pre, cur) => pre + (cur.find((f) => f.year == m) ? cur.find((f) => f.year == m).payments : 0), 0)
                                                    }
                                                })
                                            })(),
                                            borderColor: `#${Math.floor(Math.random() * 16777215).toString(16)}`,
                                            pointStyle: false,
                                            hidden: true
                                        }
                                    ]
                                })(),
                                labels
                            }} options={options}></Line>
                        </Paper>
                    </Grid>
                </Grid>
                <Grid container item xs={12}>
                    <Grid item xs={8}>
                        <Typography variant='h6' style={{ flexGrow: 1 }}>Savings & Investments</Typography>
                    </Grid>
                    <Grid container item xs={4} justifyContent='flex-end'>
                        <Button onClick={() => setHelpText("In the short-medium term, savings help you pay for things like holidays, home improvements and emergencies. These expenses could be covered by loans and credit, but if you can avoid costly interest, you should. In the longer term, savings interest can turn even modest sums of money into significant sums to aid your retirement, go on once in a lifetime holidays and support your family through major life events.")}>LEARN MORE</Button>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Paper style={{ padding: '10px' }}>
                        <Line data={{
                            datasets: getSavingsIncludingPension(finances).map((m) => {
                                return {
                                    label: m.name,
                                    data: (() => {
                                        var res = calculateSavings(finances, m, false).filter((f) => f.year < maxYear)
                                        return labels.map((p) => {
                                            return {
                                                x: p,
                                                y: res.find((y) => y.year == p)?.total
                                            }
                                        })
                                    })(),
                                    borderColor: `#${Math.floor(Math.random() * 16777215).toString(16)}`,
                                    pointStyle: false
                                }
                            }),
                            labels

                        }} options={options}></Line>
                    </Paper>
                </Grid>
                <Grid item container spacing={2} xs={12}>
                    <Grid item xs={12} md={6}>
                        <Paper>
                            <DataGrid disableColumnResize autoHeight disableColumnMenu disableRowSelectionOnClick columns={
                                [
                                    {
                                        field: 'id'
                                    },
                                    {
                                        field: 'name',
                                        flex: 0.4,
                                        headerName: 'Name'
                                    },
                                    {
                                        field: 'apr',
                                        flex: 0.25,
                                        headerName: 'Avg. AER',
                                        renderCell: (a) => {
                                            return <PercentTextFormat value={a.row.apr} />
                                        }
                                    },
                                    {
                                        field: 'edit',
                                        align: 'right',
                                        flex: 0.175,
                                        renderCell: (a) => {
                                            return <IconButton component={Link} to={a.row.type == 'pension' ? `/pension` : `/savings/${a.id}`}><EditIcon /></IconButton>
                                        },
                                        headerName: ''
                                    }
                                ]
                            } initialState={{
                                columns: {
                                    columnVisibilityModel: {
                                        id: false
                                    }
                                },
                                pagination: {
                                    paginationModel: {
                                        pageSize: 5
                                    }
                                }
                            }} rows={getSavingsIncludingPension(finances).map((m) => {
                                return {
                                    id: m.id,
                                    name: m.name,
                                    type: m.type,
                                    // TODO fix dates
                                    apr: Math.round((m.periods.reduce((prev, cur) => prev + ((cur.end ? ((dayjs(cur.end, 'YYYY-MM-DD').year() - dayjs(cur.start, 'YYYY-MM-DD').year()) + 1) : (2070 - dayjs(cur.start, 'YYYY-MM-DD').year())) * cur.interest), 0) / m.periods.reduce((prev, cur) => prev + (cur.end ? ((dayjs(cur.end, 'YYYY-MM-DD').year() - dayjs(cur.start, 'YYYY-MM-DD').year()) + 1) : 2070 - dayjs(cur.start, 'YYYY-MM-DD').year()), 0)) * 100)
                                }
                            })} />
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <AdviceCards type='savings' />
                    </Grid>
                </Grid>
                <Grid container item xs={12}>
                    <Grid item xs={6}>
                        <Typography variant='h6' style={{ flexGrow: 1 }}>Credit</Typography>
                    </Grid>
                    <Grid container item xs={6} justifyContent='flex-end'>
                        <Button onClick={() => setHelpText("If there were only disadvantages to credit, nobody would use it. Having credit it fine, in fact, over 70% of the UK population rely on it. But it's important to use credit wisely. If using credit, be aware of the consequences. If using credit to pay for a once in a lifetime holiday, go for it if you are happy with the repayments and amount of interest you'll be charged. If using credit to get by day-to-day, understand it is not sustainable in the longer term.")}>LEARN MORE</Button>
                    </Grid>
                </Grid>
                <Grid container spacing={2} item xs={12}>
                    <Grid item xs={12}>
                        <Paper style={{ padding: '10px' }}>
                            <Line data={{
                                datasets: finances.credit.map((m) => {
                                    return {
                                        label: m.name,
                                        data: (() => {
                                            var rp = calculateRepayments(finances, m)
                                            var done = false
                                            return rp.length > 0 && labels.map((p) => {
                                                var t = rp.find((y) => y.year == p)
                                                return {
                                                    x: p,
                                                    y: (() => {
                                                        if (p > rp[rp.length - 1].year && !t) {
                                                            if (!done) {
                                                                done = true
                                                                return 0
                                                            }
                                                            return null
                                                        } else if (t) {
                                                            return t.endAmount
                                                        } else if (p < rp[0].year) {
                                                            return null
                                                        }
                                                    })()
                                                }
                                            })
                                        })(),
                                        borderColor: `#${Math.floor(Math.random() * 16777215).toString(16)}`,
                                        pointStyle: false
                                    }
                                }),
                                labels

                            }} options={options}></Line>
                        </Paper>
                    </Grid>
                </Grid>
                <Grid container item xs={12} spacing={2}>
                    <Grid order={{
                        xs: 1,
                        md: 0
                    }} item xs={12} md={6}>
                        <AdviceCards type='credit' />
                    </Grid>
                    <Grid oorder={{
                        xs: 0,
                        md: 1
                    }} item xs={12} md={6}>
                        <Paper>
                            <DataGrid disableColumnResize autoHeight disableColumnMenu disableRowSelectionOnClick columns={
                                [
                                    {
                                        field: 'id'
                                    },
                                    {
                                        field: 'name',
                                        flex: 0.5,
                                        headerName: 'Name'
                                    },
                                    {
                                        field: 'paid',
                                        flex: 0.3,
                                        headerName: 'Repaid',
                                        renderCell: (a) => {
                                            return <MoneyTextFormat value={a.row.paid} />
                                        }
                                    },
                                    {
                                        field: 'interest',
                                        flex: 0.3,
                                        headerName: 'Interest',
                                        renderCell: (a) => {
                                            return <MoneyTextFormat value={a.row.interest} />
                                        }
                                    },
                                    {
                                        field: 'edit',
                                        align: 'right',
                                        flex: 0.175,
                                        renderCell: (a) => {
                                            return <IconButton component={Link} to={`/credit/${a.id}`}><EditIcon /></IconButton>
                                        },
                                        headerName: ''
                                    }
                                ]
                            } initialState={{
                                columns: {
                                    columnVisibilityModel: {
                                        id: false,
                                        interest: matches
                                    }
                                },
                                pagination: {
                                    paginationModel: {
                                        pageSize: 5
                                    }
                                }
                            }} rows={finances.credit.map((m) => {
                                var repayments = calculateRepayments(finances, m)
                                return {
                                    id: m.id,
                                    name: m.name,
                                    paid: Math.round(repayments.reduce((prev, cur) => prev + cur.payments, 0)),
                                    interest: Math.round(repayments.reduce((prev, cur) => prev + cur.interest, 0))
                                }
                            })} />
                        </Paper>
                    </Grid>
                </Grid>
                <Grid container item xs={12}>
                    <Grid item xs={6}>
                        <Typography variant='h6' style={{ flexGrow: 1 }}>Loans</Typography>
                    </Grid>
                    <Grid container item xs={6} justifyContent='flex-end'>
                        <Button onClick={() => setHelpText("Much like credit, loans are a necessity of life for many of us and without it, we wouldn't be able to buy houses or invest in our future with further education. They are in investment in life that can enable us to increase our incomes and acquire assets to secure our future. But like credit, it's important to understand the interest you'll pay on a loan to decide whether the consequence is worth the reward.")}>LEARN MORE</Button>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Paper style={{ padding: '10px' }}>
                        <Line data={{
                            datasets: finances.loans.map((m) => {
                                return {
                                    label: m.type == 'student' ? `Student Loan (${m.who == 'partner' ? 'Partner' : 'You'})` : m.name,
                                    data: (() => {
                                        var rp = calculateRepayments(finances, m)
                                        var done = false
                                        return labels.map((p) => {
                                            var t = rp.find((y) => y.year == p)
                                            return {
                                                x: p,
                                                y: (() => {
                                                    if (p > rp[rp.length - 1].year && !t) {
                                                        if (!done) {
                                                            done = true
                                                            return null
                                                        }
                                                        return null
                                                    } else if (t) {
                                                        return t.endAmount
                                                    } else if (p < rp[0].year) {
                                                        return null
                                                    }
                                                })()
                                            }
                                        })
                                    })(),
                                    borderColor: `#${Math.floor(Math.random() * 16777215).toString(16)}`,
                                    pointStyle: false
                                }
                            }),
                            labels

                        }} options={options}></Line>
                    </Paper>
                </Grid>
                <Grid container item xs={12} spacing={2}>
                    <Grid item xs={12} md={6}>
                        <Paper>
                            <DataGrid disableColumnResize autoHeight disableColumnMenu disableRowSelectionOnClick columns={
                                [
                                    {
                                        field: 'id'
                                    },
                                    {
                                        field: 'name',
                                        flex: 0.5,
                                        headerName: 'Name'
                                    },
                                    {
                                        field: 'paid',
                                        flex: 0.3,
                                        headerName: 'Repaid',
                                        renderCell: (a) => {
                                            return <MoneyTextFormat value={a.row.paid} />
                                        }
                                    },
                                    {
                                        field: 'interest',
                                        flex: 0.3,
                                        headerName: 'Interest',
                                        renderCell: (a) => {
                                            return <MoneyTextFormat value={a.row.interest} />
                                        }
                                    },
                                    {
                                        field: 'edit',
                                        align: 'right',
                                        flex: 0.175,
                                        renderCell: (a) => {
                                            return <IconButton component={Link} to={`/loan/${a.id}`}><EditIcon /></IconButton>
                                        },
                                        headerName: ''
                                    }
                                ]
                            } initialState={{
                                columns: {
                                    columnVisibilityModel: {
                                        id: false,
                                        interest: matches
                                    }
                                },
                                pagination: {
                                    paginationModel: {
                                        pageSize: 5
                                    }
                                },
                                sorting: {
                                    sortModel: [{ field: 'paid', sort: 'desc' }]
                                }
                            }} rows={finances.loans.map((m) => {
                                var repayments = calculateRepayments(finances, m)
                                return {
                                    id: m.id,
                                    name: m.type == 'student' ? `Student Loan (${m.who == 'you' ? 'You' : 'Partner'})` : m.name,
                                    paid: Math.round(repayments.reduce((prev, cur) => prev + cur.payments, 0)),
                                    interest: Math.round(repayments.reduce((prev, cur) => prev + cur.interest, 0))
                                }
                            })} />
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <AdviceCards type='loans' />
                    </Grid>
                </Grid>
                <Grid container item xs={12}>
                    <Grid item xs={6}>
                        <Typography variant='h6' style={{ flexGrow: 1 }}>Ad-hoc Expenses</Typography>
                    </Grid>
                    <Grid container item xs={6} justifyContent='flex-end'>
                        <Button onClick={() => setHelpText("Ad-hoc Expenses are ones that don't occur every year, but are significant from a financial planning perspective.")}>LEARN MORE</Button>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Paper>
                        <DataGrid disableColumnResize autoHeight disableColumnMenu disableRowSelectionOnClick columns={
                            [
                                {
                                    field: 'id'
                                },
                                {
                                    field: 'expense',
                                    flex: 0.5,
                                    headerName: 'Expense'
                                },
                                {
                                    field: 'amount',
                                    flex: 0.3,
                                    headerName: 'Amount',
                                    renderCell: (a) => {
                                        return <MoneyTextFormat value={a.row.amount} />
                                    }
                                },
                                {
                                    field: 'year',
                                    flex: 0.3,
                                    headerName: 'Year'
                                },
                                {
                                    field: 'edit',
                                    align: 'right',
                                    flex: 0.175,
                                    renderCell: (a) => {
                                        return <IconButton component={Link} to={`/expense/${a.id}`}><EditIcon /></IconButton>
                                    },
                                    headerName: ''
                                }
                            ]
                        } initialState={{
                            columns: {
                                columnVisibilityModel: {
                                    id: false,
                                    year: matches
                                }
                            },
                            pagination: {
                                paginationModel: {
                                    pageSize: 10
                                }
                            },
                            sorting: {
                                sortModel: [{ field: 'year', sort: 'asc' }]
                            }
                        }} rows={finances.expenses.filter((f) => f.type == 'other' && f.periods.length == 1 && dayjs(f.periods[0].end, 'YYYY-MM-DD').year() == dayjs(f.periods[0].start, 'YYYY-MM-DD').year()).sort((a, b) => a.periods[0].start - b.periods[0].start).map((m) => {
                            return {
                                id: m.id,
                                expense: m.type == 'other' ? m.customname : m.name,
                                amount: m.periods[0].amount,
                                year: dayjs(m.periods[0].start, 'YYYY-MM-DD').year()
                            }
                        })} />
                    </Paper>
                </Grid>
            </Grid >
        </>

    );
}

export default Outlook;
